import { getRoutedPropertyById } from "@src/store/features/property/service";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProposalPackages } from "@src/api/proposal";
import ProposalDetails from "./components/proposalDetails/ProposalDetails";
import PaymentCardDetails from "@src/components/paymentCardDetails/PaymentCardDetails";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { IoIosArrowRoundBack } from "react-icons/io";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import { toast } from "react-toastify";
import { isFulfilled } from "@reduxjs/toolkit";

export default function CreateProposal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isReadyForPayment, setIsReadyForPayment] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const [proposalFormData, setProposalFormData] = useState({
    propertyId: id,
    buyerMessage: "",
    timelineMin: 2,
    timelineMax: 7,
    packageType: null,
  });
  const [currentProposalData, setCurrentProposalData] = useState();
  const [paymentId, setPaymentId] = useState("");
  const stripePromise = useMemo(
    () => loadStripe(import.meta.env.VITE_STRIPE_API_KEY),
    [],
  );

  const property = useSelector((state) => state.property.currentRoutedProperty);
  const isLoadingPropertyDetails = useSelector(
    (state) => state.property.isLoadingRoutedProperty,
  );
  const loggedInUser = useSelector((state) => state.user.user);

  const getPackageOptions = async () => {
    try {
      const response = await getProposalPackages();

      const filteredResponse = response?.filter(
        (item) => item?.title === "Homeowner Care Package",
      );
      if (filteredResponse?.length === 1) {
        setProposalFormData({
          ...proposalFormData,
          packageType: filteredResponse?.[0]?.id,
        });
        setPackageOptions(filteredResponse);
      } else {
        setPackageOptions(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPropertyDetails = async () => {
    const response = await dispatch(getRoutedPropertyById(id));
    if (!isFulfilled(response)) {
      toast.error("Could not find that property");
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    if (loggedInUser?.id === property?.owner_id && property?.id === Number(id)) {
      toast.error("You own this property!");
      navigate("/", { replace: true });
    }
  }, [property?.owner_id, id, loggedInUser?.id]);

  useEffect(() => {
    if (property?.id !== id) getPropertyDetails();
    if (!packageOptions?.length) getPackageOptions();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <section className="flex w-full flex-col items-center py-[74px]">
        {!isReadyForPayment ? (
          <ProposalDetails
            packageOptions={packageOptions}
            proposalFormData={proposalFormData}
            proposalId={currentProposalData?.id}
            setCurrentProposalData={setCurrentProposalData}
            setIsReadyForPayment={setIsReadyForPayment}
            setPaymentId={setPaymentId}
            setProposalFormData={setProposalFormData}
          />
        ) : (
          <div className="mx-6">
            <button
              onClick={() => setIsReadyForPayment(false)}
              className="mt-2 flex items-center gap-1 rounded-xl border-[1px] bg-sage-400 px-3 py-2 font-medium uppercase text-black"
            >
              <IoIosArrowRoundBack size="26px" />
              Edit package
            </button>
            <PaymentCardDetails
              proposalId={currentProposalData?.id}
              paymentId={paymentId}
              price={currentProposalData?.price}
            />
          </div>
        )}
      </section>
      {isLoadingPropertyDetails ? <OverlayLoader /> : null}
    </Elements>
  );
}
