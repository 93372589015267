import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import PropertyProfileImage from "../propertyProfileImage/PropertyProfileImage.jsx";
import { MdOutlineIosShare } from "react-icons/md";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";
import { toast } from "react-toastify";
import PropertyProfileChatButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileChatButton.jsx";
import { SIGNIN_MODAL_TYPE } from "@src/utils/constants";
import PropertyProfileFavoriteButton from "@src/components/propertyProfile/propertyProfileButtons/PropertyProfileFavoriteButton";
import PropertyProfileEstimatedValueTag from "@src/components/propertyProfile/propertyProfileEstimatedValueTag/PropertyProfileEstimatedValueTag.jsx";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import SignInModal from "@src/components/signInModal/SignInModal.jsx";

function PropertyProfileContent({ property }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const loggedInUser = useSelector((state) => state.user.user);

  const [signinModalType, setSigninModalType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: property.address_line_1,
          text: `Check out this property at ${property.address_line_1}, ${property.city}, ${property.state}`,
          url: `${window.location.origin}/profile/${property.id}`,
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      alert("Sharing is not supported in this browser.");
    }
  };

  const handleChat = () => {
    setRedirectUrl(`/conversations/create/${property?.id}`);
    if (!isLoggedIn) {
      setSigninModalType(SIGNIN_MODAL_TYPE.CHAT);
      return;
    }

    if (property?.owner_id !== loggedInUser?.id)
      navigate(`/conversations/create/${property?.id}`);
    else toast.error("You own this property!");
  };

  const onClickSignModalOverlay = () => {
    setSigninModalType("");
    setRedirectUrl("");
  };

  return (
    <motion.div className="relative flex h-full flex-col gap-4 p-0">
      <div className="relative flex h-full w-full">
        <PropertyProfileImage property={property} />
        <div className="absolute bottom-0 left-0 right-0 flex h-1/2 flex-col items-start justify-end bg-gradient-to-t from-black/70 to-transparent p-4 pb-6 text-left">
          <PropertyProfileEstimatedValueTag property={property} />

          <PropertyProfileHeadline
            property={property}
            className="mb-1 mt-2 font-attention text-2xl font-normal capitalize text-white"
          />
          <PropertyProfileSubheading
            property={property}
            className="mb-2 text-sm text-white"
          />
          <div className="mt-1">
            <PropertyProfileDetails property={property} />
          </div>

          <div className="mt-3 flex flex-row gap-3">
            {property?.owner_id !== loggedInUser?.id || !property?.owner_id ? (
              <PropertyProfileChatButton onClick={handleChat} />
            ) : null}
            <PropertyProfileFavoriteButton
              propertyId={property?.id}
              isLiked={property?.liked}
            />
            <button
              className="flex aspect-square h-10 w-10 items-center justify-center rounded-full bg-black/60 p-1.5"
              onClick={handleShare}
            >
              <MdOutlineIosShare className="h-6 w-6 -translate-y-[1px] fill-white/60" />
            </button>
          </div>
        </div>
      </div>
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
    </motion.div>
  );
}

export default PropertyProfileContent;
