import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import property_perks from "../../data/property_perks.js";
import SelectableTag from "../SelectableTag.jsx";
import { setPropertyPropValue } from "../../store/features/property/slice.js";

function UpdatePropertyProfilePerks({ property, onChange }) {
  const dispatch = useDispatch();
  const [ownerPerks, setOwnerPerks] = useState([]);

  const handleTagChange = async (id) => {
    let newOwnerPerks = [];
    if (ownerPerks?.includes(id)) {
      newOwnerPerks = ownerPerks.filter((item) => item !== id);
    } else {
      newOwnerPerks = [...ownerPerks, id];
    }
    setOwnerPerks(newOwnerPerks);
    dispatch(
      setPropertyPropValue({ key: "owner_perks", value: newOwnerPerks }),
    );
    onChange();
  };

  const isPerkSelected = (id) => {
    return ownerPerks?.includes(id);
  };

  useEffect(() => {
    // if owner_perks is === null, then create an array and loop through the
    // property_perks and add the id to the array if they are true on the top level object
    if (property?.owner_perks === null) {
      let newOwnerPerks = [];
      property_perks.forEach((perk) => {
        if (property[perk.id]) {
          newOwnerPerks.push(perk.id);
        }
      });
      setOwnerPerks(newOwnerPerks);
    } else {
      setOwnerPerks(property?.owner_perks || []);
    }
  }, []);

  return (
    <div className="">
      <div className="flex flex-row flex-wrap items-start justify-center gap-4 gap-x-3 p-10">
        {property_perks.map((perk, index) => {
          return (
            <SelectableTag
              id={perk.id}
              name={`update_${perk.id}`}
              emoji={perk.emoji}
              text={perk.text}
              key={index}
              selectable={true}
              selected={isPerkSelected(perk.id)}
              onChange={handleTagChange}
            />
          );
        })}
      </div>
    </div>
  );
}

export default UpdatePropertyProfilePerks;
